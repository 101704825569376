import { DateTime, Interval } from 'luxon';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const MD_FORMAT = 'MM-dd';

export const getCurrentDate = (format?: string): string => {
    return format ? DateTime.local().toFormat(format) : DateTime.local().toString();
};

export const getDateFromFormat = (date: string, from: string, to: string = DATE_TIME_FORMAT): string => {
    return DateTime.fromFormat(date, from).toFormat(to);
};

export const getDateFromISO = (date: string, toFormat: string = DATE_TIME_FORMAT): string => {
    return date ? DateTime.fromISO(date).toFormat(toFormat) : '';
};

export const getTimeFromISO = (date: string): string => {
    return DateTime.fromISO(date).toFormat(TIME_FORMAT);
};

export const isValidDate = (date: string, format = DATE_FORMAT): boolean => {
    try {
        return DateTime.fromFormat(date, format).isValid;
    } catch (e) {
        return false;
    }
};

export const isValidISODate = (date: string): boolean => {
    try {
        return DateTime.fromISO(date).isValid;
    } catch (e) {
        return false;
    }
};

export const getEndDateFilter = (date: string, format = DATE_FORMAT): string => {
    return DateTime.fromFormat(date, format).plus({ days: 1 }).toFormat(DATE_FORMAT);
};

export const getEndISODateFilter = (date: string): string | null => {
    return DateTime.fromISO(date).plus({ days: 1 }).toISO();
};

export const getLastDaysDateRange = (days: number): [string | null, string | null] => {
    return [DateTime.local().minus({ days }).toISODate(), DateTime.local().toISODate()];
};

export const getLastWeekDateRange = (value: number): [string | null, string | null] => {
    return [
        DateTime.local().minus({ weeks: value }).toISODate(),
        DateTime.local().toISODate()
    ];
};

export const getLastMonthsDateRange = (value: number): [string | null, string | null] => {
    return [
        DateTime.local().minus({ months: value }).toISODate(),
        DateTime.local().toISODate()
    ];
};

export const getDatesArrayInterval = (start: DateTime, end: DateTime, outFormat: string = DATE_TIME_FORMAT) => {
    const interval = Interval.fromDateTimes(start, end);
    return Array.from(days(interval)).map(item => item.toFormat(outFormat));
};

// Get DateTime array of days by interval
function* days(interval: Interval) {
    let cursor = interval.start.startOf('day');

    while (cursor <= interval.end) {
        yield cursor;
        cursor = cursor.plus({ days: 1 });
    }
}
